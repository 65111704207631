@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Montserrat", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #191919;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  width: 90%;
  background-color: #191919;
  border-radius: 15px;
  position: relative;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
       align-items: center;
    padding-left: 2%;
    padding-right: 2%;
}

.modal-payment-system {
  width: 180px;
}

.modal-text {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 4em;
}

.shop-oferta span {
  font-weight: 500;
}

.modal-celistriks {
  height: 28px;
  width: 28px;
}

.modal-payment-system-text {
    color: #FFF;
    font-size: 1.6em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5em;
}

.navbar-container {
  width: 80%;
  margin: 1em auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5%;
  position: fixed;
  top: 1em;
  left: 0;
  right: 0;
  z-index: 1000;

  border-radius: 15px;
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: blur(50px);
}

.navbar-logo h1 {
  color: #FFF;
  font-size: 1.6em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.shop-errors {
    color: #ff7d7d;
    display: flex;
    gap: 1em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.navbar-links ul {
  display: flex;
  gap: 2.5em;
  padding-right: 1em;
  list-style: none;
  color: #FFF;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navbar-links ul li {
  color: white;
  cursor: pointer;
}

.navbar-logo-img {
  width: unset;
  height: unset;
}

.cta-container {
  background-image: url("./Images/main-bg.avif");
  min-height: 102vh;
  background-size: cover;
  background-position: center;
  margin-top: -1em;
  padding: 10% 10% 10% 10%;
}

.cta-content-container {
  width: 60%;
  margin-top: 14%;
}

.cta-h1 {
  color: #FFF;
  font-size: 5.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 98.24%;
}

.cta-p {
  margin-top: 1em;
  color: #FFF;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cta-skytowns {
  background: linear-gradient(90deg, #bcd9ef 0%, #01b7f1 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.cta-buttons {
    margin-top: 2em;
    display: flex;
    gap: 1em;
}

.cta-button {
  color: #FFF;
  padding: 1em 2em;
  font-size: 1.05em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.join {
  border-radius: 8px;
  background: rgba(0, 180, 255, 0.24);
  backdrop-filter: blur(32px);
}

.discord {
  border-radius: 8px;
  background: rgba(88, 101, 242, 0.24);
  backdrop-filter: blur(32px);
}

.map {
  border-radius: 8px;
  background: rgba(0, 255, 225, 0.24);
  backdrop-filter: blur(32px);
}


.skytowns-container {
    margin: 0 auto;
    background: #151515;;
    padding: 2% 10%;
}

.skytowns-h2 {
  color: #FFF;
  font-size: 2.3em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bento-grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin-top: 2em;
}

.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 29px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #FFF;
  font-size: 1.67em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grid-item.large {
  grid-column: span 2;
  grid-row: span 2;
}

.grid-item.medium {
  grid-column: span 2;
}

.grid-item.small {
  grid-column: span 1;
}

.grid-item.wide {
  grid-column: span 4;
}

.grid-city {
  background: linear-gradient(91deg, #FF7A00 0.58%, #FF8C38 73.51%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-enchantments {
  background: linear-gradient(90deg, #C66AFF 0%, #F5B5FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-monsters {
  background: linear-gradient(103deg, #ec9b9b -18.56%, #f33838 112.62%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-bosses {
  background: linear-gradient(125deg, #B64949 -0.46%, #986199 67.51%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-cataclism {
  background: linear-gradient(90deg, #439635 0%, #84D776 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-globus {
  background: linear-gradient(90deg, #67E3FF 29.5%, #12E942 94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-decor {
  background: linear-gradient(90deg, #8b572f 0%, #ffbe8d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-generation {
  background: linear-gradient(90deg, #6CC370 0%, rgba(9, 184, 121, 0.38) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-leaderboard {
  background: linear-gradient(90deg, #5ADD7F 0%, #64F04D 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-kits {
  background: linear-gradient(90deg, #BE34FF 0%, #4603fd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-crates {
  background: linear-gradient(90deg, #FF9D0B 0%, #FFA011 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.grid-siege {
  background: linear-gradient(90deg, #A246AA 0%, #FF9A7A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-size: 200% 200%;
  animation: gradientAnimation 3s ease infinite;
}

.HowToJoin-container {
  margin: 0 auto;
  background: #191919;
  padding: 2% 10%;
}

.HowToJoin-h3 {
  color: #FFF;
  font-size: 1.6em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.HowToJoin-p {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.833%;
  margin-top: 1.3em;
}

.HowToJoin-text {
  width: 70%;
}

.HowToJoin-image {
  width: 30%;
}

.first-step-container {
  justify-content: center;
  place-items: center;
  display: flex;
}

.HowToJoin-buttons {
  margin-top: 2em;
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 60%;
}

.HowToJoin-button {
  color: #FFF;
  padding: 0.7em 1em;
  font-size: 1.05em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
}

.official {
  border-radius: 8px;
  background: rgba(66, 255, 0, 0.24);
  backdrop-filter: blur(32px);
}

.tl-legacy {
  border-radius: 8px;
  background: rgba(27, 105, 0, 0.24);
  backdrop-filter: blur(32px);
}

.pojav {
  border-radius: 8px;
  background: rgba(15, 60, 0, 0.24);
  backdrop-filter: blur(32px);
  width: calc(200% + 1em);
}

.rules {
  background: #B1722E;
  border-radius: 8px;
  backdrop-filter: blur(32px)
}

.copy {
  border-radius: 8px;
  background: rgba(0, 180, 255, 0.25);
  backdrop-filter: blur(32px);
}

.discord-join {
  border-radius: 8px;
  background: #4A55E3;
  backdrop-filter: blur(32px);
}

.shop-container {
    margin: 0 auto;
    background: #151515;
  padding-bottom: 2em;
}

.shop-currency-select {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  background-color: #272727 !important;
  color: rgb(255, 255, 255);
  font-size: 1.3em;
  font-weight: 600;
  cursor: pointer;
  appearance: none;
  padding: 0 2.6em 0 1em;
}

.shop-amount-input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.shop-currency-select::after {
  content: '▼';
  padding-left: 0.5em;
  color: rgba(255, 255, 255, 0.50);
}

.shop-currency-select-arrow {
  z-index: 100;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 1em;
  padding-top: 1px;
  font-size: 1.3em;
}

.shop-input,
.shop-currency-select {
  border-radius: 9px;
  border: 2px solid #272727;
  background: #151515;
}

.shop-input::placeholder {
  color: rgba(255, 255, 255, 0.50);
}

.shop-input:focus,
.shop-currency-select:focus {
  outline: none;
}

.shop-options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1380px;
}

.shop-price {
  color: #FFF;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-align: right;
  justify-content: center;
  justify-self: center;
  place-self: center;
  width: 100%;
  margin-bottom: 1em;
}

.shop-options-text-fields {
  display: flex;
  gap: 1em;
  width: 100%;
  box-sizing: border-box;
}
.shop-above {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  padding: 2% 10%;
}

.shop-input {
  border-radius: 9px;
  border: 2px solid #272727;
  background: #151515;
  padding: 0.5em 1em;
  color: rgba(255, 255, 255, 0.50);
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.shop-input::placeholder {
  color: rgba(255, 255, 255, 0.50);
}

.shop-input:focus {
  outline: none;
}

.shop-nickname-input,
.shop-email-input,
.shop-amount-container {
  flex-grow: 1;
  width: 100%;
}

.shop-amount-input {
  width: 100%;
}

.shop-options-prices-container {
  margin-top: 1em;
  border-radius: 8px;
  background: #191919;
  padding: 1em;
  display: flex;
  gap: 1em;
  width: 100%;
  box-sizing: border-box;
}

.options-left {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  flex-grow: 1;
  min-width: 30em;
  min-height: 15em;
  justify-content: space-between;
  padding: 1em 2em;
  place-items: center;
}

.options-right {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  flex-grow: 1;
  justify-content: space-between;
  padding: 1em 4em;
  place-items: center;
}

.shop-options-150 {
  background: linear-gradient(101deg, #191919 1.52%, #532F24 99.53%);
  margin-bottom: 1em;
}

.shop-options-300 {
  background: linear-gradient(101deg, #191919 1.52%, #534924 99.53%);
  margin-bottom: 1em;
}

.shop-options-500 {
  background: linear-gradient(101deg, #191919 1.52%, #4C5324 99.53%);
}

.shop-options-1500 {
  background: linear-gradient(101deg, #191919 1.52%, #173D2F 99.53%);
  min-width: 53em;
  min-height: 15em;
  margin-bottom: 1em;
}

.shop-options-2500 {
  background: var(--Linear, linear-gradient(101deg, #191919 1.52%, #244B53 99.53%));
  min-width: 53em;
  min-height: 31em;
  justify-content: space-between;
  padding: 1em 4em;
  place-items: end;
}

.container-2500 {
  display: flex;
  gap: 21em;
  place-items: center;
}

.options-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
}

.shop-options-image {
  width: 197px;
  height: 197px;
}

.shop-options-price {
  color: #FFF;
  text-align: center;
  font-size: 3.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.shop-options-text {
  color: #FFF;
  text-align: center;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.oferta-buy-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    place-items: center;
    margin-top: 1.3em;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    max-width: 1380px;
}

.shop-oferta {
  color: #FFF;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 70%;
}

.shop-oferta a {
  color: #67E3FF;
}

.modal-oferta {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 0.3em;
}

.turnstile-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.shop-buy-button {
  color: #FFF;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 9px;
  background: linear-gradient(101deg, #684107 1.52%, #572B58 99.53%);
  backdrop-filter: blur(32px);
  align-self: end;
}

.animate-hover {
  transition: transform 0.3s ease;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
}

.animate-hover:hover {
  transform: scale(0.9);
}

.footer-container {
  background: #191919;
  display: flex;
  padding: 2% 10%;
  place-items: center;
  justify-content: space-between;
}

.footer-logo-container {
  margin-bottom: 7px;
  margin-top: -4px;
}

.footer-h1 {
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 1.6em;
}

.footer-p {
  color: #FFF;
  text-align: left;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-link {
  color: #FFF;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-legal-p {
  color: #8A8A8A;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-info-links {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
}

.footer-systems-logos {
    display: flex;
    gap: 1em;
    flex-direction: column;
}


/* Responsive */

@media only screen and (min-width: 3400px) {
  .cta-content-container {
    width: 50%;
    margin-top: 3% !important;
  }
}

@media only screen and (min-width: 2400px) {
  .bento-grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr) !important;
    grid-gap: 20px;
    margin-top: 2em;
  }

  .cta-content-container {
    width: 50%;
    margin-top: 8%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {

  .navbar-logo h1 {
    font-size: 1.2em;
  }

  .navbar-links ul {
    font-size: 1.1em;
  }

  .navbar-logo-img {
    width: 50px;
    height: 50px;
  }

  .cta-container {
    min-height: 80vh;
  }

  .cta-content-container {
    width: 60%;
    margin-top: 10%;
  }

  .cta-button {
    color: #FFF;
    padding: 1em 1em;
    font-size: 1.05em;
  }

  .cta-h1 {
    color: #FFF;
    font-size: 3.7em;
    font-style: normal;
    font-weight: 600;
    line-height: 98.24%;
  }

  .skytowns-h2 {
    font-size: 1.3em;
  }

  .item-overlay {
    font-size: 1.1em;
  }

    .HowToJoin-h3 {
        font-size: 1.3em;
    }

    .HowToJoin-p {
        font-size: 1.1em;
    }

  /* Shop */

    .shop-price {
        font-size: 1.2em;
    }

    .shop-above {
      padding: unset;
    }

    .shop-container {
        padding: 2% 10%;
    }

    .shop-options-1500 {
      min-width: 10em;
    }

    .shop-options-2500 {
      min-width: 10em;
    }

    .shop-options-prices-container {
        padding: 1em;
        width: 90% !important;
    }

    .shop-options-text-fields {
      width: 90% !important;
    }

    .shop-options-price {
        font-size: 2.5em;
    }

    .shop-options-text {
        font-size: 1.5em;
    }

  .container-2500 {
    gap: 1em
  }

  .HowToJoin-button {
    font-size: 1.1em;
  }

  .shop-options-1500 {
    gap: 1em;
  }

  .options-left {
    height: 5em;
  }

  .options-right {
    height: 5em;
  }

  .shop-options-prices-container {
    width: unset;
  }

  /* Footer */

    .footer-container {
        padding: 2% 5%;
    }

    .footer-h1 {
        font-size: 1.4em;
    }

    .footer-p {
        font-size: 0.9em;
    }

    .footer-legal-p {
        font-size: 0.9em;
    }

    .footer-link {
        font-size: 1em;
    }

    .footer-systems-logos {
        gap: 1em;
        scale: 0.9;
    }


}


@media only screen and (max-width: 1200px) {

  .shop-options-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 2% 10%;
  }

    .shop-above {
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 2% 10%;
    }

    .shop-price {
        place-self: center;
    }

    .shop-options-text-fields {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;
        box-sizing: border-box;
    }

    .shop-input {
        width: 100%;
    }

    .shop-nickname-input,
    .shop-email-input,
    .shop-amount-container {
        flex-grow: 1;
        width: 100%;
    }

    .shop-amount-input {
        width: 100%;
    }

    .shop-options-prices-container {
        margin-top: 1em;
        border-radius: 8px;
        background: #191919;
        padding: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;
        box-sizing: border-box;
    }

    .options-left {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        flex-grow: 1;
        min-width: 30em;
        min-height: 15em;
        justify-content: space-between;
        padding: 1em 2em;
        place-items: center;
    }

    .options-right {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        flex-grow: 1;
        justify-content: space-between;
        padding: 1em 4em;
        place-items: center;
    }

    .shop-options-150 {
        background: linear-gradient(101deg, #191919 1.52%, #532F24 99.53%);
        margin-bottom: 1em;
      min-width: unset;
      min-height: unset;
    }

    .shop-options-300 {
        background: linear-gradient(101deg, #191919 1.52%, #534924 99.53%);
        margin-bottom: 1em;
      min-width: unset;
      min-height: unset;
    }

    .shop-options-500 {
      min-width: unset;
      min-height: unset;
        background: linear-gradient(101deg, #191919 1.52%, #4C5324 99.53%);
    }

    .shop-options-1500 {
        background: linear-gradient(101deg, #191919 1.52%, #173D2F 99.53%);
      min-width: unset;
      min-height: unset;
        margin-bottom: 1em;
    }

    .shop-options-2500 {
        background: var(--Linear, linear-gradient(101deg, #191919 1.52%, #244B53 99.53%));
        min-width: unset;
        min-height: unset;
        justify-content: center;
        padding: 1em 4em;
        place-items: center;
    }

    .container-2500 {
        display: flex;
        flex-direction: column;
        gap: 1em;
        place-items: center;
    }

    .options-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-items: center;
    }

    .shop-options-image {
        width: 197px;
        height: 197px;
    }

    .shop-options-price {
        color: #FFF;
        text-align: center;
        font-size: 3.5em;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .shop-price {
      text-align: center;
    }

    .oferta-buy-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      place-items: center;
      gap: 2em;
      padding: 2% 10%;
      padding-bottom: 2em;
    }

    .shop-buy-button {
      justify-self: center;
      align-self: center;
    }

    .modal-container {
      width: 90%;
      max-height: 80vh;
      overflow-y: auto;
      padding: 4%;
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal-payment-system-container {
        display: flex;
        flex-direction: column;
        gap: 0.1em;
        justify-content: start;
        align-items: start;
        padding: 0;
        margin-top: 1em;
    }

    .modal-payment-system-text {
      text-align: left;
      font-size: 1.5em;
      font-weight: 600;
    }

    .modal-text {
      width: 100%;
      gap: 1.4em;
      padding: 2%;
      white-space: nowrap;
    }

    .modal-skin img {
      margin-top: 1.4em;
      margin-bottom: 1.4em;
      height: 230px;
    }

    .modal-oferta {
        display: flex;
        flex-direction: column;
        gap: 1.4em;
      padding: 0;
      font-size: 0.8em;
      text-align: center;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100%;
    }

    .modal-oferta button {
      margin-bottom: 1em;
    }

    .cf-turnstile {
      max-width: 100%; /* Адаптивная ширина */
      height: auto;    /* Автоматическая высота */
    }

    .shop-errors {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.2em;
    }
}

@media only screen and (max-width: 1200px) {

  .navbar-container {
    justify-content: center;
    width: max-content;
    padding: 0.7em 0.7em;
    margin: 0 auto;
  }

  .navbar-links ul {
    display: none;
  }

  .navbar-links ul li {
    display: none;
  }

  .cta-container {
    padding: 40% 8% 10% 8%;
  }

  .cta-content-container {
    width: 100%;
  }

  .cta-buttons {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

.cta-h1 {
color: #FFF;
font-size: 3em;
font-style: normal;
font-weight: 600;
line-height: 98.24%;
}

.skytowns-h2 {
  margin-top: 1em;
  font-size: 1.8em;
  text-align: center;
}

  .bento-grid-container {
    grid-template-columns: repeat(1, 2fr);
    grid-gap: 15px;
  }

  .grid-item {
    aspect-ratio: 1 / 1;
  }

  .skytowns-container {
    padding-bottom: 2em;
  }

  .grid-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 344px;
    min-height: 344px;
    border-radius: 15px;
  }

  .item-overlay {
    font-size: 1.2em;
    padding: 20px;
    z-index: 1;
  }

    .grid-item.large {
        grid-column: span 1;
        grid-row: span 1;
    }

    .grid-item.medium {
        grid-column: span 1;
    }

    .grid-item.small {
        grid-column: span 1;
    }

    .grid-item.wide {
        grid-column: span 1;
    }

    .HowToJoin-container {
        padding: 2% 5%;
      padding-bottom: 2em;
    }

    .first-step-container {
        flex-direction: column;
        margin-top: 4em;
      justify-content: center;
      place-items: center;
      display: flex;
      padding-right: 4%;
      padding-left: 4%;
      width: 100%;
    }

    .HowToJoin-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

  .HowToJoin-text {
    width: 100%;
  }

  .HowToJoin-image {
    width: 100%;
    max-width: 100%;
    display: none;
  }

  .pojav {
    width: 100%;
  }

  .footer-container {
    flex-direction: column;
    padding: 2% 5%;
  }

  .footer-logo-container {
    margin-bottom: 1em;
    margin-top: 1em;
  }

    .footer-h1 {
        font-size: 1.4em;
        text-align: center;
    }

    .footer-main-h1 {
      text-align: unset !important;
    }

    .footer-info-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        justify-content: center;
        place-items: center;
      margin-top: 1em;
    }

    br {
        display: none;
    }

    .footer-p {
        font-size: 0.9em;
    }

    .footer-legal-p {
      font-size: 0.9em;
      text-align: center;
    }

    .footer-link {
        font-size: 1em;
    }

    .footer-info-links {
        display: flex;
        flex-direction: column;
        gap: 0.4em;
        justify-content: center;
      place-items: center;
    }

    .footer-systems-logos {
        display: flex;
        gap: 1em;
        flex-direction: column;
      scale: 0.7;
    }

}